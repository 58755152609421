import { credential, general } from '../http';

export const AuthLogin = (data) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return general.post(`/auth/login`, data, config);
};

export const AuthRegister = (payload) => {
    return credential.post(`/auth/register`, payload);
};

export const DecodeJWT = () => {
    return credential.get(`/auth/decodeJWT`);
};
