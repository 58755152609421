// assets
import { PointOfSale } from "@mui/icons-material";
import DashboardIcon from '@mui/icons-material/Dashboard';

// constant
const icons = { PointOfSale, DashboardIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'kasir',
            title: 'Kasir',
            type: 'item',
            url: '/kasir',
            icon: icons.PointOfSale,
            target: true
        }
    ]
};

export default dashboard;
