import { credential } from '../http';

export const GetAllRole = () => {
    return credential.get('/permission/roles');
};

export const ListRole = () => {
    return credential.get('/permission/roles/list_role');
};

export const GetAllPermission = () => {
    return credential.get('/permission');
};

export const GetAllPermissionReal = () => {
    return credential.get('/permission/list');
};

export const GetAllRolePermissionByRoleID = (payload) => {
    let path = `/permission/role-permission/${payload}`;
    return credential.get(path);
};

export const UpdateRoleByID = (role_id, data) => {
    return credential.put(`/permission/roles/${role_id}`, data)
}

// export const UpdateRolePermissionByID = (id, payload) => {
//     return credential.put('/permission/role-permission/' + id, payload);
// };

export const CreateRole = (payload) => {
    return credential.post('/permission/role-permission', payload);
};

export const DeleteRole = (role_id) => {
    return credential.delete('/permission/roles/' + role_id);
};

export const CreatePermission = (payload) => {
    return credential.post('/permission' + payload);
};

export const UpdatePermissionByID = (permission_id, payload) => {
    return credential.put('/permission/' + permission_id, payload);
};

export const DeletePermissionByID = (permission_id) => {
    return credential.delete('/permission/' + permission_id);
};
