import PropTypes from 'prop-types';
import { lazy } from 'react';

// project imports
import { AuthContext } from 'context/authContext';
import MainLayout from 'layout/MainLayout';
import { useContext } from 'react';
import { Navigate } from 'react-router';
import Loadable from 'ui-component/Loadable';

// administrator routing
const Users = Loadable(lazy(() => import('views/administrator/users')));
const Roles = Loadable(lazy(() => import('views/administrator/roles')));

// fitur routing
const Product = Loadable(lazy(() => import('views/fitur/product')));
const Transaction = Loadable(lazy(() => import('views/fitur/transaction')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const Profile = Loadable(lazy(() => import('views/utilities/Profile')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const ProtectedCms = ({ page }) => {
    const { user } = useContext(AuthContext);
    if (!user) {
        return <Navigate to="/login" />;
    }

    if (user.page !== 'admin') {
        return <Navigate to="/login" />;
    }

    return page;
};

ProtectedCms.propTypes = {
    page: PropTypes.any
};

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <ProtectedCms page={<DashboardDefault />} />
        },
        {
            path: '',
            children: [
                {
                    path: '',
                    element: <ProtectedCms page={<DashboardDefault />} />
                }
            ]
        },
        // ============= ADMINISTRATOR ============= //
        {
            path: 'administrator',
            children: [
                {
                    path: 'users',
                    element: <ProtectedCms page={<Users />} />
                }
            ]
        },
        {
            path: 'administrator',
            children: [
                {
                    path: 'roles',
                    element: <ProtectedCms page={<Roles />} />
                }
            ]
        },
        // ============= FITUR START ============= //
        {
            path: 'product',
            children: [
                {
                    path: '',
                    element: <ProtectedCms page={<Product />} />
                }
            ]
        },
        {
            path: 'transaction',
            children: [
                {
                    path: '',
                    element: <ProtectedCms page={<Transaction />} />
                }
            ]
        },
        // ============= FITUR END ============= //
        // ============= OTHERS START ============= //
        {
            path: 'account',
            children: [
                {
                    path: '',
                    element: <ProtectedCms page={<Profile />} />
                }
            ]
        },
        // ============= OTHERS END ============= //
        // ============= UTILS START ============= //
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <ProtectedCms page={<UtilsTypography />} />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <ProtectedCms page={<UtilsColor />} />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
