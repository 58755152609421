// assets
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'other',
    type: 'group',
    children: [
        {
            id: 'account',
            title: 'Profil',
            type: 'item',
            url: '/account',
            icon: icons.IconUser,
            breadcrumbs: false
        }
        // ,
        // {
        //     id: 'documentation',
        //     title: 'Documentation',
        //     type: 'item',
        //     url: 'https://',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // }
    ]
};

export default other;
