import { GetAllProduct } from 'api/service/product';
import { ADD_TRANSACTION, UPDATE_TRANSACTION } from './TransactionAction';

export const KASIR_LIST_PRODUCT = 'KASIR_LIST_PRODUCT';
export const KASIR_LIST_TRANSACTION = 'KASIR_LIST_TRANSACTION';
export const KASIR_LIST_TRANSACTION_UPDATE = 'KASIR_LIST_TRANSACTION_UPDATE';
export const KASIR_LIST_TRANSACTION_DELETE = 'KASIR_LIST_TRANSACTION_DELETE';
export const KASIR_LIST_TRANSACTION_RESET = 'KASIR_LIST_TRANSACTION_RESET';
export const KASIR_DIALOG_DONE = 'KASIR_DIALOG_DONE';
export const KASIR_ADD_DISCOUNT = 'KASIR_ADD_DISCOUNT';
export const KASIR_REFUND_TRANS = 'REFUND_TRANS';

export const akum = {
    subTotal: 0,
    discount: 0,
    total: 0,
    metode_pembayaran: 'tunai',
    money: 0,
    kembalian: 0
}

export const kasir = () => {
    return (dispatch) => {
        dispatch({
            type: KASIR_LIST_PRODUCT,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        let object_product = [];

        // login api
        GetAllProduct()
            .then((res) => {
                res.data.data.Data.map((item) => (
                    object_product.push({
                        label: item.ProductID,
                        name: item.nama_barang,
                        stock: item.stock
                    })
                ))

                // behasil get api
                dispatch({
                    type: KASIR_LIST_PRODUCT,
                    payload: {
                        data: object_product,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: KASIR_LIST_PRODUCT,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });
            });
    };
};

export const kasirListTransaction = (data, oldData) => {
    return (dispatch) => {
        dispatch({
            type: KASIR_LIST_TRANSACTION,
            payload: {
                data: null,
                loading: true,
            }
        });

        const index = oldData.findIndex(object => {
            return object.productID === data.productID;
        });

        if (index !== -1) {
            oldData[index].count++;
            oldData[index].total = (oldData[index].count * oldData[index].price) - oldData[index].discount

            akum.subTotal = oldData.reduce((accumulator, object) => { return accumulator + (object.total); }, 0);
            akum.total = akum.subTotal - akum.discount;

            dispatch({
                type: KASIR_LIST_TRANSACTION,
                payload: {
                    data: oldData,
                    akumulasi: akum,
                    loading: false,
                }
            });
        } else {
            let obj = [...oldData, data];
            data.total = (data.price * data.count) - data.discount;

            akum.subTotal = obj.reduce((accumulator, object) => { return accumulator + (object.total); }, 0);
            akum.total = akum.subTotal - akum.discount;

            dispatch({
                type: KASIR_LIST_TRANSACTION,
                payload: {
                    data: obj,
                    akumulasi: akum,
                    loading: false,
                }
            });
        }
    }
}

export const kasirListTransactionUpdate = (data, oldData) => {
    return (dispatch) => {
        dispatch({
            type: KASIR_LIST_TRANSACTION,
            payload: {
                data: null,
                loading: true,
            }
        });

        const index = oldData.findIndex(object => {
            return object.productID === data.productID;
        });

        oldData[index].discount = data.discount
        oldData[index].count = data.count
        oldData[index].total = (data.count * data.price) - data.discount;

        akum.subTotal = oldData.reduce((accumulator, object) => { return accumulator + (object.total); }, 0);
        // akum.discount = 0;
        akum.total = akum.subTotal - akum.discount;

        dispatch({
            type: KASIR_LIST_TRANSACTION,
            payload: {
                data: oldData,
                akumulasi: akum,
                loading: false,
            }
        });
    }
}

export const kasirListTransactionDelete = (id, oldData) => {
    return (dispatch) => {

        const newData = oldData.filter((obj) => obj.productID !== id);
        akum.subTotal = newData.reduce((accumulator, object) => { return accumulator + (object.total); }, 0);
        akum.total = akum.subTotal - akum.discount;

        dispatch({
            type: KASIR_LIST_TRANSACTION_DELETE,
            payload: {
                data: newData,
                akumulasi: akum,
                loading: false
            }
        })
    }
}

export const kasirListTransactionReset = () => {
    return (dispatch) => {
        akum.subTotal = 0
        akum.total = 0
        akum.metode_pembayaran = 'tunai'
        akum.discount = 0
        akum.money = 0
        akum.kembalian = 0
        dispatch({
            type: KASIR_LIST_TRANSACTION_RESET,
            payload: {
                data: [],
                akumulasi: {
                    subTotal: 0,
                    discount: 0,
                    total: 0,
                    metode_pembayaran: 'tunai',
                    money: 0,
                    kembalian: 0
                },
                loading: false
            }

        })

        dispatch({
            type: UPDATE_TRANSACTION,
            payload: {
                data: null,
                loading: false
            }
        })

        dispatch({
            type: KASIR_REFUND_TRANS,
            payload: {
                show: false
            }
        })

        dispatch({
            type: ADD_TRANSACTION,
            payload: {
                data: null,
                loading: false,
                errorMessage: false
            }
        });
    }
}

export const kasirAddDiscount = (oldData, value) => {
    return (dispatch) => {

        oldData.discount = value
        oldData.total = oldData.subTotal - oldData.discount

        dispatch({
            type: KASIR_ADD_DISCOUNT,
            payload: {
                akumulasi: oldData
            }
        })
    }
}

export const kasirDialogDoneOpen = (show, data) => {
    return (dispatch) => {
        dispatch({
            type: KASIR_DIALOG_DONE,
            payload: {
                open: show,
                data: data
            }
        })
    }
}

export const kasirRefundTrans = (open) => {
    return (dispatch) => {
        dispatch({
            type: KASIR_REFUND_TRANS,
            payload: {
                show: open
            }
        })
    }
}
