// assets
import { IconBrandChrome, IconHelp, IconReceipt } from '@tabler/icons';


// constant
const icons = { IconBrandChrome, IconHelp, IconReceipt };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const fitur = {
    id: 'fitur',
    title: 'Fitur',
    type: 'group',
    children: [
        {
            id: 'fitur-product',
            title: 'product',
            type: 'item',
            url: '/product',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'fitur-transaction',
            title: 'Transaksi',
            type: 'item',
            url: '/transaction',
            icon: icons.IconReceipt,
            breadcrumbs: false
        }
    ]
};

export default fitur;
