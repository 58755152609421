import { credential } from "../http";

export const GetAllProduct = () => {
  // const config = {
  //     headers: { Authorization: token }
  // };
  return credential.get("/product");
};

export const UpdateProductByID = (payload) => {
  return credential.put(`/product/${payload.ProductID}`, payload);
};

export const CreateProductByUploadFile = (payload) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return credential.post("/product/create-by-file", payload, config);
};

export const CreateProduct = (payload) => {
  return credential.post("product", payload);
};

export const SoftDeleteProduct = (product_id) => {
  return credential.delete(`/product/${product_id}`);
};

export const GetProductByID = (product_id) => {
  return credential.get(`/product/${product_id}`);
};

export const DownloadFileProduct = () => {
  const config = {
    responseType: "blob",
    // onDownloadProgress: progressEvent => {
    //     const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
    //     const current = progressEvent.currentTarget.response.length

    //     let percentCompleted = Math.floor(current / total * 100)
    //     console.log('completed: ', percentCompleted)
    // }
  };
  return credential.get("/product/download", config);
};
