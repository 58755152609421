import { ErrorCode } from 'api/errorCode';
import { CreateTransaction, GetAllInvoice, GetTransByNoInvoice, UpdateTrans } from 'api/service/transaction';
import { KASIR_LIST_TRANSACTION } from './KasirAction';
import { TRANSACTION_SNACKBAR } from './Snackbar';

export const GET_ALL_TRANSACTION = 'GET_ALL_TRANSACTION';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const GET_TRANSACTION_BY_INVOICE = 'GET_TRANSACTION_BY_INVOICE';
// export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';

export const getAllTransaction = () => {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_TRANSACTION,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        GetAllInvoice()
            .then((res) => {
                // behasil get api
                dispatch({
                    type: GET_ALL_TRANSACTION,
                    payload: {
                        data: res.data.data.data,
                        loading: false,
                        errorMessage: null
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: GET_ALL_TRANSACTION,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });

                // snackbar
                dispatch({
                    type: TRANSACTION_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: err.response.status,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const addTransaction = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_TRANSACTION,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        CreateTransaction(payload)
            .then((res) => {
                console.log(res.data.data.no_invoice)
                dispatch({
                    type: ADD_TRANSACTION,
                    payload: {
                        data: res.data.data.no_invoice,
                        loading: false,
                        errorMessage: null
                    }
                });

                // snackbar
                dispatch({
                    type: TRANSACTION_SNACKBAR,
                    payload: {
                        message: "transaksi berhasil",
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: ADD_TRANSACTION,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });

                // snackbar
                dispatch({
                    type: TRANSACTION_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const updateTransaction = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_TRANSACTION,
            payload: {
                data: null,
                loading: true
            }
        })

        UpdateTrans(payload)
            .then((res) => {
                dispatch({
                    type: UPDATE_TRANSACTION,
                    payload: {
                        data: res.data.data,
                        loading: false
                    }
                })

                // snackbar
                dispatch({
                    type: TRANSACTION_SNACKBAR,
                    payload: {
                        message: `perubahan data ${payload.transaction_id} berhasil dilakukan`,
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_TRANSACTION,
                    payload: {
                        data: null,
                        loading: false
                    }
                })

                // snackbar
                dispatch({
                    type: TRANSACTION_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            })
    }
}

export const getTransByNoInvoice = (no_invoice) => {
    return (dispatch) => {
        dispatch({
            type: GET_TRANSACTION_BY_INVOICE,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        })

        // get data
        GetTransByNoInvoice(no_invoice)
            .then((res) => {
                dispatch({
                    type: GET_TRANSACTION_BY_INVOICE,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: null
                    }
                })

                let akumulasi = {
                    subTotal: res.data.data.subTotal,
                    discount: res.data.data.discount,
                    total: res.data.data.total,
                    metode_pembayaran: res.data.data.method,
                    money: res.data.data.Cash,
                    kembalian: res.data.data.kembalian
                }

                let object = []

                res.data.data.ListItemTransaction.forEach((val) => {
                    object.push({
                        item_transaction_id: val.item_transaction_id,
                        productID: val.product_id,
                        product_name: val.product_name,
                        count: val.count,
                        price: val.price,
                        discount: val.discount,
                        total: val.total
                    })
                })

                dispatch({
                    type: KASIR_LIST_TRANSACTION,
                    payload: {
                        data: object,
                        akumulasi: akumulasi,
                        loading: false,
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_TRANSACTION_BY_INVOICE,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });

                // snackbar
                dispatch({
                    type: TRANSACTION_SNACKBAR,
                    payload: {
                        message: 'invoice ' + ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            })
    }
}