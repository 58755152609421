import axios from 'axios';
const service_url = process.env.REACT_APP_SERVICE_URL;
export const cashier = process.env.REACT_APP_PAGE_KASIR;
export const cms = process.env.REACT_APP_PAGE_CMS;
export const auth = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : undefined;

export const general = axios.create({
    baseURL: service_url
});

export const credential = axios.create({
    baseURL: service_url,
    headers: {
        Authorization: auth ? auth.access_token : undefined
    }
});

// credential.interceptors.request.use(
//   (request) => {
//     return request;
//   },
//   (error) => {
//     return Promise.reject(error)
//   })

// credential.interceptors.response.use(
//   (request) => {
//     return request;
//   },
//   (error) => {
//     if (error.response.status === 404) {
//       return Promise.reject("error not found cuk!!!")
//     } else if (error.response.status === 401) {
//       return Promise.reject(error)
//     }
//   })
