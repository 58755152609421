import { credential } from '../http';

export const GetAllInvoice = () => {
    return credential.get('/transaction/invoice');
};

export const CreateTransaction = (payload) => {
    return credential.post('/transaction', payload);
}

export const UpdateTrans = (payload) => {
    console.log("NO INVOICE/trans : ", payload.transaction_id)
    return credential.put(`/transaction/invoice/${payload.transaction_id}`, payload);
}

export const GetTransByNoInvoice = (no_invoice) => {
    const config = {
        headers: { "Content-Type": "application/json" }
    }
    return credential.get(`/transaction/invoice/${no_invoice}`, config);
}