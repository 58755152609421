import {
    MODAL_ADD_ROLE,
    MODAL_ADD_USER,
    MODAL_OPEN,
    MODAL_UPDATE_KASIR,
    MODAL_UPDATE_PRODUCT,
    MODAL_UPDATE_ROLE,
    MODAL_UPDATE_USER,
    NOTIFICATION_LOW_STOCK
} from 'store/actions/ModalAction';
import { UPDATE_DATA_ADD_ROLE } from 'store/actions/Permission';

const initialState = {
    transactionModal: false,

    addRoleModal: false,
    addRoleShowData: null,

    updateRoleModal: false,
    updateRoleModalData: null,

    updateProductShow: false,
    updateProductShowData: null,

    addUserShow: false,

    updateUserShow: false,
    updateUserShowData: null,

    updateKasirShow: false,
    updateKasirShowData: null,

    lowStockShow: false
};

const modal = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_OPEN:
            return {
                ...state,
                transactionModal: action.payload.open
            };
        case MODAL_ADD_ROLE:
            return {
                ...state,
                addRoleModal: action.payload.open,
                addRoleShowData: action.payload.data
            };
        case MODAL_UPDATE_ROLE:
            return {
                ...state,
                updateRoleModal: action.payload.open,
                updateRoleModalData: action.payload.role
            };
        case MODAL_UPDATE_PRODUCT:
            return {
                ...state,
                updateProductShow: action.payload.open,
                updateProductShowData: action.payload.data
            };
        case MODAL_ADD_USER:
            return {
                ...state,
                addUserShow: action.payload.open
            };
        case MODAL_UPDATE_USER:
            return {
                ...state,
                updateUserShow: action.payload.open,
                updateUserShowData: action.payload.data
            };
        case MODAL_UPDATE_KASIR:
            return {
                ...state,
                updateKasirShow: action.payload.open,
                updateKasirShowData: action.payload.data
            };
        case UPDATE_DATA_ADD_ROLE:
            return {
                ...state,
                addRoleShowData: action.payload.data
            };
        case NOTIFICATION_LOW_STOCK:
            return {
                ...state,
                lowStockShow: action.payload.open
            };
        default:
            return state;
    }
};

export default modal;
