import { lazy } from 'react';

// project imports
import { AuthContext } from 'context/authContext';
import MinimalLayout from 'layout/MinimalLayout';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Navigate } from 'react-router';
import Loadable from 'ui-component/Loadable';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

const Kasir = Loadable(lazy(() => import('views/kasir')));
const Print = Loadable(lazy(() => import('views/kasir/print')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //


const ProtectedKasir = ({ page }) => {
    const { user } = useContext(AuthContext);
    if (!user) {
        return <Navigate to="/login" />;
    }

    if (user.page !== 'kasir') {
        return <Navigate to="/login" />;
    }

    return page;
};

ProtectedKasir.propTypes = {
    page: PropTypes.any
};

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        },
        {
            path: '/kasir',
            element: <ProtectedKasir page={<Kasir />} />
        },
        {
            path: '/print',
            element: <Print />
        }
    ]
};

export default AuthenticationRoutes;
