// material-ui
import {
    Avatar, Chip, Grid,
    List,
    ListItem,
    ListItemAvatar, ListItemText, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// assets

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //
// const status = [
//     {
//         value: 'all',
//         label: 'All Notification'
//     },
//     {
//         value: 'new',
//         label: 'New'
//     },
//     {
//         value: 'unread',
//         label: 'Unread'
//     },
//     {
//         value: 'other',
//         label: 'Other'
//     }
// ];
const NotificationList = () => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    // state low stock
    const { getLowStockData } = useSelector((state) => state.dashboard);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {getLowStockData && getLowStockData.map((item) => (
                <ListItemWrapper key={item.ProductID}>
                    <ListItem alignItems="center">
                        <ListItemAvatar>
                            <Avatar
                                sx={{
                                    color: theme.palette.success.dark,
                                    backgroundColor: theme.palette.success.light,
                                    border: 'none',
                                    borderColor: theme.palette.success.main
                                }}
                            >
                                P
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<Typography variant="subtitle1">{item.ProductID}</Typography>} />
                    </ListItem>
                    <Grid container direction="column" className="list-container">
                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Typography variant="subtitle2">{item.nama_barang} telah berada dibawah batas, segera lakukan penambahan stok</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item>
                                    <Chip label={item.stock + " tersisa"} sx={chipErrorSX} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItemWrapper>
            )
            )}

        </List>
    );
};

export default NotificationList;
