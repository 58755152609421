import { useEffect, useRef } from 'react';

// material-ui
import {
    Avatar,
    Box, ButtonBase, Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack, Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getLowStock } from 'store/actions/DashboardAction';
import { openLowStock } from 'store/actions/ModalAction';

// notification status options
// const status = [
//     {
//         value: 'all',
//         label: 'All Notification'
//     },
//     {
//         value: 'new',
//         label: 'New'
//     },
//     {
//         value: 'unread',
//         label: 'Unread'
//     },
//     {
//         value: 'other',
//         label: 'Other'
//     }
// ];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const {
        lowStockShow,
        // addRoleShowData 
    } = useSelector((state) => state.modal);

    // state low stock
    const {
        getLowStockData,
        // getLowStockLoading,
        // getLowStockError
    } = useSelector((state) => state.dashboard);

    const {
        getAllProductData
    } = useSelector((state) => state.products);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        dispatch(openLowStock(!lowStockShow))
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        dispatch(openLowStock(false))
    };

    const prevOpen = useRef(lowStockShow);
    useEffect(() => {
        if (prevOpen.current === true && lowStockShow === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = lowStockShow;
    }, [lowStockShow]);

    useEffect(() => {
        if (getAllProductData != null) {
            dispatch(getLowStock());
        }
    }, [dispatch, getAllProductData])

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px', visibility: "hidden" }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: getLowStockData ? '#ff6666' : theme.palette.secondary.light,
                            color: getLowStockData ? 'whitesmoke' : theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: getLowStockData ? '#ff6666' : theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={lowStockShow ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={lowStockShow}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={lowStockShow} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">Produk Dibawah Limit</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={getLowStockData?.length}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <NotificationList />
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    {/* <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button size="small" disableElevation>
                                            View All
                                        </Button>
                                    </CardActions> */}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
