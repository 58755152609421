import { CONVERT_ROLE, DELETE_USER, GET_USERS, GET_USER_BY_ID, UPDATE_USER } from 'store/actions/UserActions';

const initialState = {
    getUserData: null,
    getUserLoading: false,
    getUserError: false,

    getUserByIdData: null,
    getUserByIdLoading: false,
    getUserByIdError: false,

    updateUserData: null,
    updateUserLoading: false,
    updateUserError: false,

    deleteUserData: null,
    deleteUserLoading: false,
    deleteUserError: false,

    convertRoleData: ''
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                getUserData: action.payload.data,
                getUserLoading: action.payload.loading,
                getUserError: action.payload.errorMessage
            };
        case GET_USER_BY_ID:
            return {
                ...state,
                getUserByIdData: action.payload.data,
                getUserByIdLoading: action.payload.loading,
                getUserByIdError: action.payload.errorMessage
            };
        case DELETE_USER:
            return {
                ...state,
                deleteUserData: action.payload.data,
                deleteUserLoading: action.payload.loading,
                deleteUserError: action.payload.errorMessage
            }
        case UPDATE_USER:
            return {
                ...state,
                updateUserData: action.payload.data,
                updateUserLoading: action.payload.loading,
                updateUserError: action.payload.errorMessage
            }
        case CONVERT_ROLE:
            return {
                ...state,
                convertRoleData: action.payload.data
            }
        default:
            return state;
    }
};

export default users;
