import {
    ADD_PRODUCT,
    ADD_PRODUCT_BY_UPLOAD_FILE,
    DELETE_PRODUCT,
    GET_ALL_PRODUCT,
    UPDATE_PRODUCT
} from 'store/actions/ProductAction';

const initialState = {
    getAllProductData: null,
    getAllProductLoading: false,
    getAllProductError: null,

    // getProductByIdData: null,

    addProductData: null,
    addProductLoading: false,
    addProductError: null,

    addProductByFileData: null,
    addProductByFileLoading: false,
    addProductByFileError: null,

    updateProductByIdData: null,
    updateProductByIdLoading: false,
    updateProductByIdError: null,

    deleteProductData: null,
    deleteProductLoading: false,
    deleteProductError: null
};

const products = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT:
            return {
                ...state,
                getAllProductData: action.payload.data,
                getAllProductLoading: action.payload.loading,
                getAllProductError: action.payload.errorMessage
            };
        case ADD_PRODUCT:
            return {
                ...state,
                addProductData: action.payload.data,
                addProductLoading: action.payload.loading,
                addProductError: action.payload.errorMessage
            };
        case ADD_PRODUCT_BY_UPLOAD_FILE:
            return {
                ...state,
                addProductByFileData: action.payload.data,
                addProductByFileLoading: action.payload.loading,
                addProductByFileError: action.payload.errorMessage
            };
        case DELETE_PRODUCT:
            return {
                ...state,
                deleteProductData: action.payload.data,
                deleteProductLoading: action.payload.loading,
                deleteProductError: action.payload.errorMessage
            };
        case UPDATE_PRODUCT:
            return {
                ...state,
                updateProductByIdData: action.payload.data,
                updateProductByIdLoading: action.payload.loading,
                updateProductByIdError: action.payload.errorMessage
            };
        // case GET_PRODUCT_BY_ID:
        //     return {
        //         ...state,
        //         getProductByIdData: action.payload.data
        //     };
        default:
            return state;
    }
};

export default products;
