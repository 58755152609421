export const USER_SNACKBAR = 'USER_SNACKBAR';
export const PRODUCT_SNACKBAR = 'PRODUCT_SNACKBAR';
export const TRANSACTION_SNACKBAR = 'TRANSACTION_SNACKBAR';
export const ROLE_SNACKBAR = 'ROLE_SNACKBAR';

export const userSnackbar = () => {
    return (dispatch) => {
        dispatch({
            type: USER_SNACKBAR,
            payload: {
                message: null,
                loading: false,
                errorCode: false,
                show: false,
                severity: 'success'
            }
        })
    }
}

export const roleSnackbar = () => {
    return (dispatch) => {
        dispatch({
            type: ROLE_SNACKBAR,
            payload: {
                message: null,
                loading: false,
                errorCode: false,
                show: false,
                severity: 'success'
            }
        })
    }
}

export const productSnackbar = () => {
    return (dispatch) => {
        dispatch({
            type: PRODUCT_SNACKBAR,
            payload: {
                message: null,
                loading: false,
                errorCode: false,
                show: false,
                severity: 'success'
            }
        });
    }
}

export const transactionSnackbar = () => {
    return (dispatch) => {
        dispatch({
            type: TRANSACTION_SNACKBAR,
            payload: {
                message: null,
                loading: false,
                errorCode: false,
                show: false,
                severity: 'success'
            }
        });
    }
}