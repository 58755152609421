import { PRODUCT_SNACKBAR, ROLE_SNACKBAR, TRANSACTION_SNACKBAR, USER_SNACKBAR } from "store/actions/Snackbar";

const initialState = {
    userSnackbarMessage: null,
    userSnackbarLoading: false,
    userSnackbarErrorCode: false,
    userSnackbarShow: false,
    userSnackbarSeverity: 'success',

    roleSnackbarMessage: null,
    roleSnackbarLoading: false,
    roleSnackbarErrorCode: false,
    roleSnackbarShow: false,
    roleSnackbarSeverity: 'success',

    productSnackbarMessage: null,
    productSnackbarLoading: false,
    productSnackbarErrorCode: false,
    productSnackbarShow: false,
    productSnackbarSeverity: 'success',

    transactionSnackbarMessage: null,
    transactionSnackbarLoading: false,
    transactionSnackbarErrorCode: false,
    transactionSnackbarShow: false,
    transactionSnackbarSeverity: 'success',
};

const Snackbar = (state = initialState, action) => {
    switch (action.type) {
        case USER_SNACKBAR:
            return {
                ...state,
                userSnackbarMessage: action.payload.message,
                userSnackbarLoading: action.payload.loading,
                userSnackbarErrorCode: action.payload.errorCode,
                userSnackbarShow: action.payload.show,
                userSnackbarSeverity: action.payload.severity
            };
        case PRODUCT_SNACKBAR:
            return {
                ...state,
                productSnackbarMessage: action.payload.message,
                productSnackbarLoading: action.payload.loading,
                productSnackbarErrorCode: action.payload.errorCode,
                productSnackbarShow: action.payload.show,
                productSnackbarSeverity: action.payload.severity
            };
        case TRANSACTION_SNACKBAR:
            return {
                ...state,
                transactionSnackbarMessage: action.payload.message,
                transactionSnackbarLoading: action.payload.loading,
                transactionSnackbarErrorCode: action.payload.errorCode,
                transactionSnackbarShow: action.payload.show,
                transactionSnackbarSeverity: action.payload.severity
            };
        case ROLE_SNACKBAR:
            return {
                ...state,
                roleSnackbarMessage: action.payload.message,
                roleSnackbarLoading: action.payload.loading,
                roleSnackbarErrorCode: action.payload.errorCode,
                roleSnackbarShow: action.payload.show,
                roleSnackbarSeverity: action.payload.severity
            };
        default:
            return state;
    }
};

export default Snackbar;