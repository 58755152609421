export const rupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR'
    }).format(number);
};

export const handlePrint = (e) => {
    e.preventDefault();
    var printContents = document.getElementById(e.target.value).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
};

export const GetDate = (startDate, endDate) => {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate));
        // Use UTC date to prevent problems with time zones and DST
        // currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
};

export const RemoveHttp = (url) => {
    if (url.startsWith('https://')) {
        const https = 'https://';
        return url.slice(https.length);
    }

    if (url.startsWith('http://')) {
        const http = 'http://';
        return url.slice(http.length);
    }

    return url;
};

const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export const convertDate = (date) => {
    let a = new Date(date);
    return [
        padTo2Digits(a.getDate()),
        padTo2Digits(a.getMonth() + 1),
        a.getFullYear(),
    ].join('-');
}