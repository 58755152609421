// assets
import { IconBrandChrome, IconHelp, IconUsers, IconShield } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconUsers, IconShield };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const administrator = {
    id: 'admin',
    title: 'Administrator',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'users',
            type: 'item',
            url: '/administrator/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'roles',
            title: 'Roles',
            type: 'item',
            url: '/administrator/roles',
            icon: icons.IconShield,
            breadcrumbs: false
        }
    ]
};

export default administrator;
