import {
    CHART_DATA,
    GET_LOW_STOCK,
    GET_REPORT_TRANSACTION_AMOUNT,
    GET_TOTAL_ORDER_ITEM_TRANSCATION
} from "store/actions/DashboardAction";

const initialState = {
    getReportTransAmountData: 0,
    getReportTransAmountLoading: false,
    getReportTransAmountError: false,

    getTotalOrderItemTransData: 0,
    getTotalOrderItemTransLoading: false,
    getTotalOrderItemTransError: false,

    getLowStockData: null,
    getLowStockLoading: false,
    getLowStockError: false,

    chartData: null,
    chartLoading: false,
    chartError: false
}

const Dashboard = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT_TRANSACTION_AMOUNT:
            return {
                ...state,
                getReportTransAmountData: action.payload.data,
                getReportTransAmountLoading: action.payload.loading,
                getReportTransAmountError: action.payload.errorMessage
            };
        case GET_TOTAL_ORDER_ITEM_TRANSCATION:
            return {
                ...state,
                getTotalOrderItemTransData: action.payload.data,
                getTotalOrderItemTransLoading: action.payload.loading,
                getTotalOrderItemTransError: action.payload.errorMessage
            };
        case GET_LOW_STOCK:
            return {
                ...state,
                getLowStockData: action.payload.data,
                getLowStockLoading: action.payload.loading,
                getLowStockError: action.payload.errorMessage
            };
        case CHART_DATA:
            return {
                ...state,
                chartData: action.payload.data,
                chartLoading: action.payload.loading,
                chartError: action.payload.errorMessage
            }
        default:
            return state;
    }
};

export default Dashboard;
