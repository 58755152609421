import administrator from './administrator';
import dashboard from './dashboard';
import fitur from './fitur';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, administrator, fitur, other]
};

export default menuItems;
