import { ErrorCode } from 'api/errorCode';
import {
    AuthLogin,
    AuthRegister,
    DecodeJWT
} from 'api/service/auth';
import { USER_SNACKBAR } from './Snackbar';

export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const DECODE_JWT = 'DECODE_JWT';

export const login = (payload) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        AuthLogin(payload)
            .then((res) => {
                // save to local storage
                localStorage.setItem('user', JSON.stringify(res.data.data));
                // behasil get api
                dispatch({
                    type: LOGIN,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: LOGIN,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });
            });
    };
};

export const registerAccount = (payload) => {
    return (dispatch) => {
        dispatch({
            type: REGISTER,
            payload: {
                data: null,
                loading: true,
                errorMessage: null
            }
        });

        // snackbar
        dispatch({
            type: USER_SNACKBAR,
            payload: {
                message: null,
                loading: true,
                errorCode: false,
                show: false,
                severity: 'success'
            }
        })

        // login api
        AuthRegister(payload)
            .then(() => {
                // behasil get api
                dispatch({
                    type: REGISTER,
                    payload: {
                        data: '201',
                        loading: false,
                        errorMessage: null
                    }
                });

                // snackbar
                dispatch({
                    type: USER_SNACKBAR,
                    payload: {
                        message: 'pengguna baru berhasil ditambahkan',
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: REGISTER,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });

                // snackbar
                dispatch({
                    type: USER_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const decodeJWT = () => {
    return (dispatch) => {
        DecodeJWT()
            .then((res) => {
                dispatch({
                    type: DECODE_JWT,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                })
            })
            .catch((err) => {
                dispatch({
                    type: DECODE_JWT,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                })
            })
    }
}