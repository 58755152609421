import { ErrorCode } from "api/errorCode";
import { GetAllReportTransaction, GetLowStock, GetReportTransactionAmount, GetTotalOrderItemTransaction } from "api/service/report";

export const GET_REPORT_TRANSACTION_AMOUNT = 'GET_REPORT_TRANSACTION_AMOUNT';
export const GET_TOTAL_ORDER_ITEM_TRANSCATION = 'GET_TOTAL_ORDER_ITEM_TRANSCATION';
export const GET_LOW_STOCK = 'GET_LOW_STOCK';
export const CHART_DATA = 'CHART_DATA';

export const getChartData = (day) => {
    return (dispatch) => {
        dispatch({
            type: CHART_DATA,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        })

        GetAllReportTransaction(day)
            .then((res) => {
                // console.log(res.data.data)
                dispatch({
                    type: CHART_DATA,
                    payload: {
                        data: res.data.data.data,
                        loading: false,
                        errorMessage: false
                    }
                })
            })
            .catch((err) => {
                dispatch({
                    type: CHART_DATA,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                })
            })
    }
}

export const getReportTransactionAmount = () => {
    return (dispatch) => {
        dispatch({
            type: GET_REPORT_TRANSACTION_AMOUNT,
            payload: {
                data: 0,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        GetReportTransactionAmount()
            .then((res) => {
                dispatch({
                    type: GET_REPORT_TRANSACTION_AMOUNT,
                    payload: {
                        data: res.data.data.amount,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_REPORT_TRANSACTION_AMOUNT,
                    payload: {
                        data: 0,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });
            });
    };
}

export const getTotalOrderItemTransaction = () => {
    return (dispatch) => {
        dispatch({
            type: GET_TOTAL_ORDER_ITEM_TRANSCATION,
            payload: {
                data: 0,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        GetTotalOrderItemTransaction()
            .then((res) => {
                dispatch({
                    type: GET_TOTAL_ORDER_ITEM_TRANSCATION,
                    payload: {
                        data: res.data.data.total_invoice,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_TOTAL_ORDER_ITEM_TRANSCATION,
                    payload: {
                        data: 0,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });
            });
    };
}

export const getLowStock = () => {
    return (dispatch) => {
        dispatch({
            type: GET_LOW_STOCK,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        GetLowStock()
            .then((res) => {
                dispatch({
                    type: GET_LOW_STOCK,
                    payload: {
                        data: res.data.data.ListStock,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_LOW_STOCK,
                    payload: {
                        data: false,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });
            });
    };
}