import { ErrorCode } from 'api/errorCode';
import { CreateProduct, CreateProductByUploadFile, GetAllProduct, SoftDeleteProduct, UpdateProductByID } from 'api/service/product';
import { PRODUCT_SNACKBAR } from './Snackbar';

export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_BY_UPLOAD_FILE = 'ADD_PRODUCT_BY_UPLOAD_FILE';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
// export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';

export const getAllProduct = () => {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_PRODUCT,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        GetAllProduct()
            .then((res) => {
                // behasil get api
                dispatch({
                    type: GET_ALL_PRODUCT,
                    payload: {
                        data: res.data.data.Data,
                        loading: false,
                        errorMessage: null
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: GET_ALL_PRODUCT,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: err.response.status,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const addProduct = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_PRODUCT,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        CreateProduct(payload)
            .then(() => {
                // behasil get api
                dispatch({
                    type: ADD_PRODUCT,
                    payload: {
                        data: 'product has been added',
                        loading: false,
                        errorMessage: null
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: 'berhasil menambahkan produk baru',
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                // err message handling
                dispatch({
                    type: ADD_PRODUCT,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: err.response.status,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const addProductByUploadFile = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_PRODUCT_BY_UPLOAD_FILE,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        CreateProductByUploadFile(payload)
            .then(() => {
                dispatch({
                    type: ADD_PRODUCT_BY_UPLOAD_FILE,
                    payload: {
                        data: 'product has been added',
                        loading: false,
                        errorMessage: null
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: 'berhasil menambahkan atau mengubah produk',
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: ADD_PRODUCT_BY_UPLOAD_FILE,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const deleteProduct = (payload) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_PRODUCT,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        SoftDeleteProduct(payload)
            .then(() => {
                // behasil get api
                dispatch({
                    type: DELETE_PRODUCT,
                    payload: {
                        data: `product ${payload} has been deleted`,
                        loading: false,
                        errorMessage: null
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: `Produk ${payload} telah dihapus`,
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                // err message handling
                dispatch({
                    type: DELETE_PRODUCT,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: err.response.status,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const updateProductByID = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PRODUCT,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        UpdateProductByID(payload)
            .then(() => {
                // behasil get api
                dispatch({
                    type: UPDATE_PRODUCT,
                    payload: {
                        data: `product ${payload.ProductID} has been updated`,
                        loading: false,
                        errorMessage: null
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: `Produk ${payload.nama_barang} berhasil diubah`,
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                // err message handling
                dispatch({
                    type: UPDATE_PRODUCT,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });

                // snackbar
                dispatch({
                    type: PRODUCT_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: err.response.status,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

// export const getProductByID = (data) => {
//     return (dispatch) => {
//         dispatch({
//             type: GET_PRODUCT_BY_ID,
//             payload: {
//                 data: data
//             }
//         });
//     };
// };
