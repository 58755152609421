const { DOWNLOAD_REPORT_TRANSACTION } = require("store/actions/Download");

const initialState = {
    downloadReportLoading: false,
    downloadReportError: false
}

const download = (state = initialState, action) => {
    switch (action.type) {
        case DOWNLOAD_REPORT_TRANSACTION:
            return {
                ...state,
                downloadReportLoading: action.payload.loading,
                downloadReportError: action.payload.errorMessage
            };
        default:
            return state;
    }
}

export default download;