import { ErrorCode } from 'api/errorCode';
import { GetAllRole } from 'api/service/permission';
import { GetAllUser, GetUserByID, SoftDeleteUser, UpdateUserByID } from 'api/service/user';
import { USER_SNACKBAR } from './Snackbar';

export const GET_USERS = 'GET_USERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CONVERT_ROLE = 'CONVERT_ROLE';

export const getUser = () => {
    return (dispatch) => {
        // loading
        dispatch({
            type: GET_USERS,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // get api
        GetAllUser()
            .then((res) => {
                dispatch({
                    type: GET_USERS,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: GET_USERS,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });

                // snackbar
                dispatch({
                    type: USER_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const getUserByID = (UserID) => {
    return (dispatch) => {
        dispatch({
            type: GET_USER_BY_ID,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // get api
        GetUserByID(UserID)
            .then((res) => {
                dispatch({
                    type: GET_USER_BY_ID,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_USER_BY_ID,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });
            });
    };
}

export const deleteUser = (user_id) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_USER,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        })

        SoftDeleteUser(user_id)
            .then((res) => {
                dispatch({
                    type: DELETE_USER,
                    payload: {
                        data: res.data.data.message,
                        loading: false,
                        errorMessage: false
                    }
                })
            })
            .catch((err) => {
                dispatch({
                    type: DELETE_USER,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                })
            })


    }
}

export const updateUser = (row) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_USER,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        })

        // snackbar
        dispatch({
            type: USER_SNACKBAR,
            payload: {
                message: null,
                loading: true,
                errorCode: false,
                show: false,
                severity: 'success'
            }
        })

        UpdateUserByID(row)
            .then((res) => {
                dispatch({
                    type: UPDATE_USER,
                    payload: {
                        data: res.data.data.message,
                        loading: false,
                        errorMessage: false
                    }
                })

                // snackbar
                dispatch({
                    type: USER_SNACKBAR,
                    payload: {
                        message: 'data pengguna berhasi di ubah',
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                })
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_USER,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                })

                // snackbar
                dispatch({
                    type: USER_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            })
    }
}

export const convertRole = () => {
    return (dispatch) => {
        GetAllRole()
            .then((res) => {
                let value = res.data.data;
                let object = value?.reduce((obj, item) => Object.assign(obj, { [item.role_id]: item.name }), {});

                dispatch({
                    type: CONVERT_ROLE,
                    payload: {
                        data: object
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: CONVERT_ROLE,
                    payload: {
                        data: null
                    }
                });
            });
    }
}
