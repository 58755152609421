import { ErrorCode } from 'api/errorCode';
import {
    CreateRole,
    DeleteRole,
    GetAllPermission,
    GetAllRole,
    GetAllRolePermissionByRoleID,
    ListRole,
    UpdateRoleByID
} from 'api/service/permission';
import { ROLE_SNACKBAR } from './Snackbar';

export const GET_ALL_ROLE = 'GET_ALL_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const GET_ALL_ROLE_PERMISSION_BY_ID = 'GET_ALL_ROLE_PERMISSION_BY_ID';
export const GET_LIST_PERMISSION = 'GET_LIST_PERMISSION';
export const UPDATE_DATA_ADD_ROLE = 'UPDATE_DATA_ADD_ROLE';
export const CREATE_NEW_ROLE = 'CREATE_NEW_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const LIST_ROLE = 'LIST_ROLE';
export const LIST_NEW_ROLE = 'LIST_NEW_ROLE';
export const LIST_NEW_ROLE_RESET = 'LIST_NEW_ROLE_RESET';
export const UPDATE_GET_ALL_ROLE_PERMISSION_BY_ID = 'UPDATE_GET_ALL_ROLE_PERMISSION_BY_ID';

export const getAllRole = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_ALL_ROLE,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        await GetAllRole()
            .then((res) => {
                dispatch({
                    type: GET_ALL_ROLE,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: GET_ALL_ROLE,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });
            });
    };
};

export const listRole = () => {
    return async (dispatch) => {
        dispatch({
            type: LIST_ROLE,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        await ListRole()
            .then((res) => {
                dispatch({
                    type: LIST_ROLE,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: LIST_ROLE,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });
            });
    };
};

export const getAllRolePermissionByID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: GET_ALL_ROLE_PERMISSION_BY_ID,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });


        let list_permission = []

        await GetAllPermission()
            .then((res) => {
                list_permission = res.data.data
            })
            .catch((err) => {
                dispatch({
                    type: GET_ALL_ROLE_PERMISSION_BY_ID,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });
            })

        // login api
        await GetAllRolePermissionByRoleID(parseInt(id))
            .then((res) => {
                // behasil get api
                list_permission?.forEach((item, index) => {

                    const index1 = res.data.data.findIndex(object => {
                        return object.name === item.name;
                    });
                    if (index1 === -1) {
                        res.data.data[index] = {
                            name: item.name,
                            permissions: []
                        }

                        item.Permissions.map((item_child, index3) => 
                            list_permission[index].Permissions[index3] = {
                                permission_id: item_child.permission_id,
                                role_permission_id: 0,
                                name: item.name,
                                description: item_child.description,
                                is_true: false
                            }
                        )
                    } else {
                        item.Permissions.forEach((item_child, index3) => {
                            const index2 = res.data.data[index1].permissions.findIndex(object => {
                                return object.permission_id === item_child.permission_id;
                            });

                            if (index2 !== -1) {
                                list_permission[index].Permissions[index3] = res.data.data[index1].permissions[index2]
                            } else {
                                list_permission[index].Permissions[index3] = {
                                    permission_id: item_child.permission_id,
                                    role_permission_id: 0,
                                    name: item.name,
                                    description: item_child.description,
                                    is_true: false
                                }
                            }
                        })
                    } 
                })

                dispatch({
                    type: GET_ALL_ROLE_PERMISSION_BY_ID,
                    payload: {
                        data: list_permission,
                        loading: false,
                        errorMessage: false
                    }
                });
            })
            .catch((err) => {
                // gagal get api
                dispatch({
                    type: GET_ALL_ROLE_PERMISSION_BY_ID,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: err.message
                    }
                });
            });
    };
};

export const updateGetAllRolePermissionByID = (data) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_GET_ALL_ROLE_PERMISSION_BY_ID,
            payload: {
                data: data
            }
        });
    };
};

export const updateRole = (id, payload) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_ROLE,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        });

        // login api
        await UpdateRoleByID(id, payload)
            .then((res) => {
                dispatch({
                    type: UPDATE_ROLE,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                });

                // // close update modal
                // dispatch({
                //     type: MODAL_UPDATE_ROLE,
                //     payload: {
                //         open: false,
                //         role: null
                //     }
                // });

                // // snackbar
                // dispatch({
                //     type: ROLE_SNACKBAR,
                //     payload: {
                //         message: `role ${payload.role_name} berhasil diubah`,
                //         loading: false,
                //         errorCode: false,
                //         show: true,
                //         severity: 'success'
                //     }
                // });
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_ROLE,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                });


                // snackbar
                dispatch({
                    type: ROLE_SNACKBAR,
                    payload: {
                        message: `role ${payload.role_name} tidak berhasil diubah`,
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            });
    };
};

export const getListPermission = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_LIST_PERMISSION,
            payload: {
                data: null,
                loading: true,
                errorMessage: false
            }
        })

        await GetAllPermission()
            .then((res) => {
                dispatch({
                    type: GET_LIST_PERMISSION,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                })
            })
            .catch((err) => {
                dispatch({
                    type: GET_LIST_PERMISSION,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                })
            })
    }
}

export const createNewRole = (payload) => {
    return async (dispatch) => {
        dispatch({
            type: CREATE_NEW_ROLE,
            payload: {
                data: null,
                loading: false,
                errorMessage: false
            }
        })

        await CreateRole(payload)
            .then((res) => {
                dispatch({
                    type: CREATE_NEW_ROLE,
                    payload: {
                        data: res.data.data,
                        loading: false,
                        errorMessage: false
                    }
                })

                // snackbar
                dispatch({
                    type: ROLE_SNACKBAR,
                    payload: {
                        message: `role ${payload.role_name} berhasil dibuat`,
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    alert('Role sudah pernah dibuat!!!');
                }
                dispatch({
                    type: CREATE_NEW_ROLE,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                })

                // snackbar
                dispatch({
                    type: ROLE_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            })
    }
}

export const deleteRole = (row) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_ROLE,
            payload: {
                data: null,
                loading: false,
                errorMessage: false
            }
        })

        await DeleteRole(row.role_id)
            .then((res) => {
                dispatch({
                    type: DELETE_ROLE,
                    payload: {
                        data: res.data.message,
                        loading: false,
                        errorMessage: false
                    }
                })

                // snackbar
                dispatch({
                    type: ROLE_SNACKBAR,
                    payload: {
                        message: `role ${row.name} telah dihapus`,
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'success'
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: DELETE_ROLE,
                    payload: {
                        data: null,
                        loading: false,
                        errorMessage: ErrorCode(err.response.status)
                    }
                })

                // snackbar
                dispatch({
                    type: ROLE_SNACKBAR,
                    payload: {
                        message: ErrorCode(err.response.status),
                        loading: false,
                        errorCode: false,
                        show: true,
                        severity: 'error'
                    }
                });
            })


    }
}

export const createListNewRolePermissions = (data) => {
    return (dispatch) => {
        dispatch({
            type: LIST_NEW_ROLE,
            payload: {
                data: data
            }
        })
    }
}

export const createListNewRolePermissionsReset = () => {
    return (dispatch) => {
        dispatch({
            type: LIST_NEW_ROLE_RESET,
            payload: {
                data: []
            }
        })
    }
}

// update list permission for create new role
export const createNewRolePermission = (oldData, permission_id) => {
    return (dispatch) => {
        const index = oldData.findIndex(object => { return object.id === permission_id });
        const updateListPermission = [...oldData];
        updateListPermission[index].is_true = !updateListPermission[index].is_true;

        dispatch({
            type: UPDATE_DATA_ADD_ROLE,
            payload: {
                data: updateListPermission
            }
        })
    }
}
