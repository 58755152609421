import { credential } from '../http';

export const GetAllUser = () => {
    return credential.get(`/users`);
};

export const UpdateUserByID = (payload) => {
    return credential.put(`/users/` + payload.id, payload);
};

export const SoftDeleteUser = (userID) => {
    return credential.delete(`/users/${userID}`);
};

export const GetUserByID = (userID) => {
    return credential.get(`/users/${userID}`)
}
