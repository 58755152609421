export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_ADD_ROLE = 'MODAL_ADD_ROLE';
export const MODAL_UPDATE_ROLE = 'MODAL_UPDATE_ROLE';
export const MODAL_UPDATE_PRODUCT = 'MODAL_UPDATE_PRODUCT';
export const MODAL_ADD_USER = 'MODAL_ADD_USER';
export const MODAL_UPDATE_USER = 'MODAL_UPDATE_USER';
export const MODAL_UPDATE_KASIR = 'MODAL_UPDATE_KASIR';
export const NOTIFICATION_LOW_STOCK = 'NOTIFICATION_LOW_STOCK';

export const openLowStock = (open) => {
    return (dispatch) => {
        dispatch({
            type: NOTIFICATION_LOW_STOCK,
            payload: {
                open: open
            }
        })
    }
}
export const modalOpen = (data) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_OPEN,
            payload: {
                open: data
            }
        });
    };
};

export const modalAddRole = (show, data) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_ADD_ROLE,
            payload: {
                open: show,
                // data: permissions
            }
        });
    };
};

export const modalUpdateRole = (show, role) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_UPDATE_ROLE,
            payload: {
                open: show,
                role: role
            }
        });
    };
};

export const modalUpdateProduct = (show, data) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_UPDATE_PRODUCT,
            payload: {
                open: show,
                data: data
            }
        });
    };
};

export const modalAddUser = (show) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_ADD_USER,
            payload: {
                open: show,
            }
        });
    };
};

export const modalUpdateUser = (show, data) => {
    // console.log(data)
    return (dispatch) => {
        dispatch({
            type: MODAL_UPDATE_USER,
            payload: {
                open: show,
                data: data
            }
        });
    };
};

export const modalUpdateKasir = (show, data) => {
    return (dispatch) => {
        dispatch({
            type: MODAL_UPDATE_KASIR,
            payload: {
                open: show,
                data: data
            }
        });
    };
};
