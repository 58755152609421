import { credential } from '../http';

export const GetAllReportTransaction = (data) => {
    return credential.get('/report/transaction?group_by=' + data);
};

export const GetReportTransactionAmount = () => {
    return credential.get('/report/transaction/amount');
};

export const GetTotalOrderItemTransaction = () => {
    return credential.get('/report/transaction/total-order');
};

export const GetAllInvoiceReportBetweenDate = (payload) => {
    const config = {
        responseType: 'blob',
        params: {
            start: payload.start,
            end: payload.end
        }
    }
    return credential.get('/report/transaction/find', config);
};

export const GetLowStock = () => {
    return credential.get('/report/product/lowstock');
};

export const DownloadReport = (payload) => {
    const config = {
        responseType: 'blob'
        // onDownloadProgress: progressEvent => {
        //     const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
        //     const current = progressEvent.currentTarget.response.length

        //     let percentCompleted = Math.floor(current / total * 100)
        //     console.log('completed: ', percentCompleted)
        // }
    }
    return credential.get(`/report/transaction/download?type=${payload}`, config);
};
