import { KASIR_ADD_DISCOUNT, KASIR_DIALOG_DONE, KASIR_LIST_PRODUCT, KASIR_LIST_TRANSACTION, KASIR_LIST_TRANSACTION_DELETE, KASIR_LIST_TRANSACTION_RESET, KASIR_REFUND_TRANS } from 'store/actions/KasirAction';

const initialState = {
    kasirData: null,
    kasirLoading: false,
    kasirError: null,

    kasirListTransData: [],
    kasirListTransAkumulasi: {
        subTotal: 0,
        discount: 0,
        total: 0,
        metode_pembayaran: 'tunai',
        money: 0,
        kembalian: 0,
    },
    kasirListTransLoading: false,

    kasirDialogDone: false,
    kasirDialogDoneData: {},

    refundTransShow: false,
    refundTransItems: [],
    refundTransAkumulasi: {
        subTotal: 0,
        discount: 0,
        total: 0,
        metode_pembayaran: 'tunai',
        money: 0,
        kembalian: 0
    },
    refundLoadingError: false

};

const Kasir = (state = initialState, action) => {
    switch (action.type) {
        case KASIR_LIST_PRODUCT:
            return {
                ...state,
                kasirData: action.payload.data,
                kasirLoading: action.payload.loading,
                kasirError: action.payload.errorMessage
            };
        case KASIR_LIST_TRANSACTION:
            return {
                ...state,
                kasirListTransData: action.payload.data,
                kasirListTransAkumulasi: action.payload.akumulasi,
                kasirListTransLoading: action.payload.loading
            };
        case KASIR_LIST_TRANSACTION_DELETE:
            return {
                ...state,
                kasirListTransData: action.payload.data,
                kasirListTransAkumulasi: action.payload.akumulasi,
                kasirListTransLoading: action.payload.loading
            };
        case KASIR_LIST_TRANSACTION_RESET:
            return {
                ...state,
                kasirListTransData: action.payload.data,
                kasirListTransAkumulasi: action.payload.akumulasi,
                kasirListTransLoading: action.payload.loading
            };
        case KASIR_DIALOG_DONE:
            return {
                ...state,
                kasirDialogDone: action.payload.open,
                kasirDialogDoneData: action.payload.data
            };
        case KASIR_ADD_DISCOUNT:
            return {
                ...state,
                kasirListTransAkumulasi: action.payload.akumulasi
            };
        case KASIR_REFUND_TRANS:
            return {
                ...state,
                refundTransShow: action.payload.show
                // refundTransItems: action.payload.items,
                // refundTransAkumulasi: action.payload.akumulasi,
                // refundLoadingError: action.payload.errorMessage
            }
        default:
            return state;
    }
};

export default Kasir;
