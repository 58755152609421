import { DECODE_JWT, LOGIN, REGISTER } from 'store/actions/AuthAction';

const initialState = {
    authData: null,
    authLoading: false,
    authError: false,

    registerData: null,
    registerLoading: false,
    registerError: false,

    decodeJWTdata: null,
    decodeJWTLoading: false,
    decodeJWTError: false
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                authData: action.payload.data,
                authLoading: action.payload.loading,
                authError: action.payload.errorMessage
            };
        case REGISTER:
            return {
                ...state,
                registerData: action.payload.data,
                registerLoading: action.payload.loading,
                registerError: action.payload.errorMessage
            };
        case DECODE_JWT:
            return {
                ...state,
                decodeJWTdata: action.payload.data,
                decodeJWTLoading: action.payload.loading,
                decodeJWTError: action.payload.errorMessage
            };
        default:
            return state;
    }
};

export default Auth;
