// import { ErrorCode } from "api/errorCode";
import { DownloadReport, GetAllInvoiceReportBetweenDate } from "api/service/report";
import { convertDate } from "func";

export const DOWNLOAD_REPORT_TRANSACTION = 'DOWNLOAD_REPORT_TRANSACTION';

export const downloadReportTrans = (payload) => {
    return (dispatch) => {
        dispatch({
            type: DOWNLOAD_REPORT_TRANSACTION,
            payload: {
                loading: true,
                errorMessage: false
            }
        })

        if (payload.type !== 'custom range') {
            DownloadReport(payload.type)
                .then((res) => {
                    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    const link = document.createElement('a');
                    const d = new Date();
                    link.href = window.URL.createObjectURL(new Blob([res.data]));
                    link.setAttribute('download', `${payload.type + " " + month[d.getMonth()] + "-" + d.getFullYear()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    dispatch({
                        type: DOWNLOAD_REPORT_TRANSACTION,
                        payload: {
                            loading: false,
                            errorMessage: false
                        }
                    })
                })
                .catch((err) => {
                    console.log("ERRor : ", err)
                    dispatch({
                        type: DOWNLOAD_REPORT_TRANSACTION,
                        payload: {
                            loading: false,
                            errorMessage: "under maintenance"
                        }
                    })
                })
        } else {
            GetAllInvoiceReportBetweenDate(payload)
                .then((res) => {
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let filename = `Report Transaksi ${convertDate(payload.start)} - ${convertDate(payload.end)}.xlsx`;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    dispatch({
                        type: DOWNLOAD_REPORT_TRANSACTION,
                        payload: {
                            loading: false,
                            errorMessage: false
                        }
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: DOWNLOAD_REPORT_TRANSACTION,
                        payload: {
                            loading: false,
                            errorMessage: "under maintenance"
                        }
                    })
                })
        }
    }
}