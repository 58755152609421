export const ErrorCode = (statusCode) => {
    let message = '';
    if (statusCode === 401) {
        message = 'unauthorized or try reload';
    } else if (statusCode === 400) {
        message = 'permintaan gagal';
    } else if (statusCode === 403) {
        message = 'anda tidak mempunyai izin';
    } else if (statusCode === 409) {
        message = 'sudah pernah dibuat';
    } else if (statusCode === 404) {
        message = 'tidak ditemukan'
    } else {
        message = 'periksa jaringan internet atau tanyakan pada superadmin';
    }
    return message;
}