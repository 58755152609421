import { GET_ALL_TRANSACTION, ADD_TRANSACTION, GET_TRANSACTION_BY_INVOICE, UPDATE_TRANSACTION } from 'store/actions/TransactionAction';

const initialState = {
    getAllTransactionData: null,
    getAllTransactionLoading: false,
    getAllTransactionError: null,

    getTransByNoInvoiceData: null,
    getTransByNoInvoiceLoading: null,
    getTransByNoInvoiceError: null,

    addTransactionData: null,
    addTransactionLoading: false,
    addTransactionError: null,

    updateTransactionData: null,
    updateTransactionLoading: false
};

const transactions = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TRANSACTION:
            return {
                ...state,
                getAllTransactionData: action.payload.data,
                getAllTransactionLoading: action.payload.loading,
                getAllTransactionError: action.payload.errorMessage
            };
        case ADD_TRANSACTION:
            return {
                ...state,
                addTransactionData: action.payload.data,
                addTransactionLoading: action.payload.loading,
                addTransactionError: action.payload.errorMessage
            };
        case GET_TRANSACTION_BY_INVOICE:
            return {
                ...state,
                getTransByNoInvoiceData: action.payload.data,
                getTransByNoInvoiceLoading: action.payload.loading,
                getTransByNoInvoiceError: action.payload.errorMessage,
            }

        case UPDATE_TRANSACTION:
            return {
                ...state,
                updateTransactionData: action.payload.data,
                updateTransactionLoading: action.payload.loading
            }
        default:
            return state;
    }
};

export default transactions;
