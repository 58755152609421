import { combineReducers } from 'redux';
import Auth from './Auth';
import customizationReducer from './CustomizationReducer';
import Dashboard from './Dashboard';
import download from './Download';
import Kasir from './Kasir';
import modal from './Modal';
import permissions from './Permission';
import products from './Product';
import Snackbar from './Snackbar';
import transactions from './Transaction/indejx';
import Users from './Users';

const reducers = combineReducers({
    customization: customizationReducer,
    users: Users,
    auth: Auth,
    products: products,
    transactions: transactions,
    modal: modal,
    permissions: permissions,
    kasir: Kasir,
    dashboard: Dashboard,
    download: download,
    snackbar: Snackbar
});

export default reducers;
