import {
    CREATE_NEW_ROLE,
    DELETE_ROLE,
    GET_ALL_ROLE,
    GET_ALL_ROLE_PERMISSION_BY_ID,
    GET_LIST_PERMISSION,
    LIST_NEW_ROLE,
    LIST_NEW_ROLE_RESET,
    LIST_ROLE,
    UPDATE_GET_ALL_ROLE_PERMISSION_BY_ID,
    UPDATE_ROLE
} from 'store/actions/Permission';

const initialState = {
    getAllRoleData: null,
    getAllRoleLoading: false,
    getAllRoleError: null,

    getAllRolePermissionByIdData: null,
    getAllRolePermissionByIdLoading: false,
    getAllRolePermissionByIdError: null,

    getListPermissionData: null,
    getListPermissionLoading: false,
    getListPermissionError: null,

    createNewRoleData: null,
    createNewRoleLoading: false,
    createNewRoleError: null,

    updateRoleData: null,
    updateRoleLoading: false,
    updateRoleError: null,

    deleteRoleData: null,
    deleteRoleLoading: false,
    deleteRoleError: null,

    listRoleData: null,
    listRoleLoading: false,
    listRoleError: false,

    listNewRoleData: []
};

const permissions = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ROLE:
            return {
                ...state,
                getAllRoleData: action.payload.data,
                getAllRoleLoading: action.payload.loading,
                getAllRoleError: action.payload.errorMessage
            };
        case GET_ALL_ROLE_PERMISSION_BY_ID:
            return {
                ...state,
                getAllRolePermissionByIdData: action.payload.data,
                getAllRolePermissionByIdLoading: action.payload.loading,
                getAllRolePermissionByIdError: action.payload.errorMessage
            };
        case UPDATE_ROLE:
            return {
                ...state,
                updateRoleData: action.payload.data,
                updateRoleLoading: action.payload.loading,
                updateRoleError: action.payload.errorMessage
            };
        case GET_LIST_PERMISSION:
            return {
                ...state,
                getListPermissionData: action.payload.data,
                getListPermissionLoading: action.payload.loading,
                getListPermissionError: action.payload.errorMessage
            };
        case CREATE_NEW_ROLE:
            return {
                ...state,
                createNewRoleData: action.payload.data,
                createNewRoleLoading: action.payload.loading,
                createNewRoleError: action.payload.errorMessage
            };
        case DELETE_ROLE:
            return {
                ...state,
                deleteRoleData: action.payload.data,
                deleteRoleLoading: action.payload.loading,
                deleteRoleError: action.payload.errorMessage
            };
        case LIST_ROLE:
            return {
                ...state,
                listRoleData: action.payload.data,
                listRoleLoading: action.payload.loading,
                listRoleError: action.payload.errorMessage
            };
        case LIST_NEW_ROLE:
            return {
                ...state,
                listNewRoleData: action.payload.data
            };
        case LIST_NEW_ROLE_RESET:
            return {
                ...state,
                listNewRoleData: action.payload.data
            };
        case UPDATE_GET_ALL_ROLE_PERMISSION_BY_ID:
            return {
                ...state,
                getAllRolePermissionByIdData: action.payload.data
            }
        default:
            return state;
    }
};

export default permissions;
